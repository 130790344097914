<template>
  <div class="login">
    <h1>Авторизация</h1>
    <form @submit.prevent="login">
      <input v-model="email" type="email" placeholder="Email" required />
      <input v-model="password" type="password" placeholder="Пароль" required />
      <button type="submit">Войти</button>
    </form>
    <p v-if="error" class="error">{{ error }}</p>

    <div class="manage-link">
      <router-link to="/admin">Управление</router-link>
    </div>
  </div>
</template>
  
<script>
  import { supabase } from '../plugins/supabase';
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        error: null,
      };
    },
    methods: {
      async login() {
        const { user, error } = await supabase.auth.signInWithPassword({
          email: this.email,
          password: this.password,
        });
  
        if (error) {
          this.error = error.message;
        } else {
          this.error = null;
          console.log('Logged in:', user);
          // Перенаправьте пользователя или сохраните его данные
          this.$router.push('/');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
  body {
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 99vh;
    max-width: 320px;
    margin: auto;
  }

  .login h1 {
    font-family: "Montserrat", serif;
  }

  .login form {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 8px;
    width: 100%;
  }

  .login input {
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 10px;
    border-radius: 6px;
    outline: none;
    border: 2px solid #000000;
    font-family: "Montserrat", serif;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }

  .login input::placeholder {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .login button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    background-color: #000000;
    border: 2px solid #000000;
    border-radius: 6px;
    outline: none;
    color: #ffffff;
    font-family: "Montserrat", serif;
    font-weight: 600;
  }

  .manage-link {
    margin-top: auto;
    margin-bottom: 20px;
  }

  .manage-link a {
    font-size: 16px;
    font-family: "Montserrat", serif;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
  }

  .error {
    color: red;
  }
  </style>