import { createRouter, createWebHistory } from 'vue-router';
// import { authGuard } from "@auth0/auth0-vue";
import MainPage from './pages/MainPage';
import { supabase } from './plugins/supabase';
import LoginPage from './pages/LoginPage.vue';
// import store from '@/store';

const router = new createRouter({
    history: createWebHistory(),
    routes : [
        {
            path: '/',
            name: 'main',
            component: MainPage,
            meta: {
                requiresAuth: true
            }
            // beforeEnter: authGuard
        }, {
            path: '/:id',
            component: MainPage,
            props: true,
            meta: {
                requiresAuth: true
            }
            // beforeEnter: authGuard
            // beforeEnter: (to) => {
            //     store.commit('SET_ACTIVE_TAB', to.params.id);
            // },
            // meta: {
            //     requiresAuth: true
            // }
        }, {
            path: '/login',
            component: LoginPage
        }, {
            path: '/admin',
            name: 'Admin',
            beforeEnter() {
                // Переход на внешний ресурс
                window.location.href = 'https://supabase.com/dashboard/project/mvwoujzbltsaixrggbfh/auth/users';
            }
        }
    ]
});
 


router.beforeEach(async (to, from, next) => {
    const { data: { user } } = await supabase.auth.getUser();
  
    if (to.matched.some((record) => record.meta.requiresAuth) && !user) {
      next('/login');
    } else {
      next();
    }
  });

export default router;