import { createClient } from '@supabase/supabase-js';

console.log(process.env)

const developmentKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxwbGJheHFrbHN5dGNsYmJoc3BvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzc4NzY1MTMsImV4cCI6MjA1MzQ1MjUxM30.s5QN2hOXAkPro0g722aYWxKvBDAnIVULusUh-p8FMLo';
const productionKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im12d291anpibHRzYWl4cmdnYmZoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzc5MDkwNTAsImV4cCI6MjA1MzQ4NTA1MH0.3pCnOfKALNnfjWZWof8PhtvkHe-uG42c-n8UgaoFQzM';

const supabaseUrl = process.env.ENV === 'development' ?
    'https://lplbaxqklsytclbbhspo.supabase.co':
    'https://mvwoujzbltsaixrggbfh.supabase.co';

const supabaseKey = process.env.ENV === 'development' ?
    developmentKey :
    productionKey;

export const supabase = createClient(supabaseUrl, supabaseKey);